@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

#contato {
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
}

#contato2 {
    background-position: center right;
}

.contato {
    margin-top: 15vw;
    padding-left: 20vw;
    padding-right: 20vw;

    .contato__title {
        font-size: 3vw;
        color: $azul-claro;
        font-weight: 700;
        font-family: "unbounded", sans-serif;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 2vw;
    }

    .contato__link {
        display: block;
        font-size: 2vw;
        color: $branco;
        font-weight: 500;
        font-family: "europa", sans-serif;
        text-align: center;
        margin-bottom: 1vw;
        text-decoration: none;

        &:hover {
            color: $azul-medio-2;
        }
    }

    .contato__img {
        display: inline-block;
        margin-right: 1vw;
        width: 4%;
    }
}


@media only screen and (max-width: 767px) {

    .contato {
        margin-top: 50vw;
        padding-left: 5vw;
        padding-right: 5vw;
    
        .contato__title {
            font-size: 8vw;
            margin-bottom: 12vw;
        }
    
        .contato__link {
            font-size: 5vw;
            margin-bottom: 8vw;
        }
    
        .contato__img {
            margin-right: 2vw;
            width: 8%;
        }
    }

}