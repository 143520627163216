@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';


.options {
    margin-top: 10vw;

    .options__col {
        position: relative;
        background-size: auto;
        background-position: center;
        background-repeat: no-repeat;
        height: 25vw;
        cursor: pointer;
        border-radius: 36px;

        &:hover {
            -webkit-animation: scale-down-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: scale-down-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            color: $branco;
        }

        .options__col__link {
            position: absolute;
            top: 40%;
            left: 10%;
            font-size: 1vw;
            width: 80%;
            color: $azul-escuro;
            font-weight: 700;
            text-decoration: none;
            font-family: "unbounded", sans-serif;
            text-transform: uppercase;
            text-align: center;
        }

        .claro {
            color: $azul-claro;
        }
    }
}




@media only screen and (max-width: 767px) {

    .options {
        margin-top: 10vw;
        padding-bottom: 20vw;
        padding-left: 8vw;
        padding-right: 8vw;

        .options__col {
            position: relative;
            background-size: auto;
            background-position: center;
            height: 50vw;
            margin-bottom: 6vw;
    
            &:hover {
                -webkit-animation: none;
                animation: none;
                color: $azul-claro;
            }
    
            .options__col__link {
                position: absolute;
                top: 40%;
                left: 10%;
                font-size: 4vw;
                width: 80%;
            }
    
            .claro {
                color: $azul-claro;
            }
        }
    }
}