@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

#servicos {
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
}

#servicos2 {
    background-position: center right;
}

.servicos {
    margin-top: 5vw;

    .servicos__title {
        font-size: 2.5vw;
        color: $azul-escuro;
        font-weight: 700;
        font-family: "unbounded", sans-serif;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 4vw;
    }

    .servicos__servico {
        position: relative;
        margin-bottom: 4vw;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 36px;
        padding: 2vw;
        border-top:  0.5vw solid $azul-escuro;
        border-bottom: 0.5vw solid $azul-escuro;

        .servicos__servico__title {
            position: absolute;
            z-index: 999;
            width: 100%;
            bottom: 0;
            font-size: 2vw;
            color: $azul-escuro;
            font-weight: 700;
            font-family: "unbounded", sans-serif;
            text-transform: uppercase;
            text-align: center;
            font-style: italic;
            margin-bottom: 0;
            padding-top: 2vw;
            padding-bottom: 1vw;
            background-color: rgba($azul-medio-2, 0.85);
            border-bottom-right-radius: 36px;
            border-bottom-left-radius: 36px;
        }

        .servicos__servico__capa {
            position: relative;
            display: block;
            background-size: cover;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            border-radius: 36px;
            margin-bottom: 2vw;
            height: 15vw;
        }

        .servicos__servico__img {
            display: block;
            width: 75%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2vw;
        }

        .servicos__servico__info {
            padding: 2vw;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 2vw;

            .servicos__servico__info__title {
                color: $azul-escuro;
                font-size: 1.25vw;
                font-weight: 700;
                font-family: "unbounded", sans-serif;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 1vw;
            }

            .servicos__servico__info__text {
                color: $azul-escuro;
                font-weight: 400;
                font-family: "europa", sans-serif;
                font-size: 1vw;
                line-height: 2;
            }
        }
    }
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    position:fixed;
    top: 50%;
}

@media only screen and (max-width: 767px) {

    .servicos {
        margin-top: 20vw;
    
        .servicos__title {
            font-size: 8vw;
            margin-bottom: 12vw;
        }
    
        .servicos__servico {
            margin-bottom: 30vw;
            width: 80%;
            border: none;
    
            .servicos__servico__title {
                position: absolute;
                z-index: 999;
                width: 100%;
                bottom: 0%;
                font-size: 5.4vw;
                margin-bottom: 0;
                padding-top: 4vw;
                padding-bottom: 4vw;
                background-color: $azul-claro;
                border-bottom-right-radius: 36px;
                border-bottom-left-radius: 36px;
                padding-left: 8vw;
                padding-right: 8vw;
            }

            
            .servicos__servico__capa {
                position: relative;
                display: block;
                background-size: cover;
                background-position: center;
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 8vw;
                height: 45vw;
            }
        
            .servicos__servico__img {
                width: 100%;
                opacity: 0.7;
            }
    
            .servicos__servico__info {
                padding: 2vw;
                width: 100%;
                margin-top: 4vw;
    
                .servicos__servico__info__title {
                    font-size: 5vw;
                    font-weight: 700;
                    font-family: "unbounded", sans-serif;
                    text-transform: uppercase;
                    text-align: center;
                    margin-bottom: 1vw;
                }
    
                .servicos__servico__info__text {
                    font-weight: 400;
                    font-family: "europa", sans-serif;
                    font-size: 4vw;
                    line-height: 2;
                }
            }
    
        }
    }

    .carousel-control-next-icon, .carousel-control-prev-icon {
        position:fixed;
        top: 47%;
    }

}