@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';

.menu {
    padding-top: 2vw;

    .menu__logo {
        position: relative;
        width: 100%;
    }

    .menu__link {
        display: block;
        position: relative;
        margin-top: 1.5vw;
        font-size: 1vw;
        text-align: center;
        color: $azul-claro;
        font-weight: 700;
        text-decoration: none;
        font-family: "unbounded", sans-serif;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            color: $branco;
        }
    }

}

.menu--reverse {
    padding-top: 2vw;

    .menu--reverse__logo {
        position: relative;
        width: 100%;
    }

    .menu--reverse__link {
        display: block;
        position: relative;
        margin-top: 1.5vw;
        font-size: 1vw;
        text-align: center;
        color: $azul-escuro;
        font-weight: 700;
        text-decoration: none;
        font-family: "unbounded", sans-serif;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
            color: $azul-medio-2;
        }
    }

}


@media only screen and (max-width: 767px) {

    .menu {
        padding-top: 10vw;
        padding-left: 4vw;
        padding-right: 4vw;

        .menu__mobile {
            position: relative;
            display: block;
            width: 80%;
            margin-top: 2vw;
        }

        .rotate {
            transform: rotate(90deg);
        }
    }

    .menu--reverse {
        padding-top: 10vw;
        padding-left: 4vw;
        padding-right: 4vw;

        .menu--reverse__mobile {
            position: relative;
            display: block;
            width: 80%;
            margin-top: 2vw;
        }

        .rotate {
            transform: rotate(90deg);
        }
    }

    #open {
        position: relative;
    }

    .open {
        position: absolute;
        top: 20vw;
        background-color: $azul-escuro;
        padding: 4vw;
        padding-bottom: 0;
        z-index: 999;
        right: -50%;
        border: 1vw solid $azul-claro;
        width: 40vw;

        .open__link {
            display: block;
            position: relative;
            margin-bottom: 4vw;
            font-size: 3.5vw;
            text-align: center;
            color: $azul-claro-2;
            font-weight: 700;
            text-decoration: none;
            font-family: "unbounded", sans-serif;
            text-transform: uppercase;
        }
    }
}