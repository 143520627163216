.scale-down-center {
	-webkit-animation: scale-down-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: scale-down-center 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.scale-up-center {
	-webkit-animation: scale-up-center 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-center 2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.slide-bck-center {
	-webkit-animation: slide-bck-center 0.45s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
	        animation: slide-bck-center 0.45s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
}

.text-flicker-in-glow {
	-webkit-animation: text-flicker-in-glow 2s linear both;
	        animation: text-flicker-in-glow 2s linear both;
}

.slide-in-left {
	-webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.text-focus-in {
	-webkit-animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: text-focus-in 2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

.slide-in-bottom {
	-webkit-animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-11-16 18:0:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-down-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-down-center {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
      }
      @keyframes scale-down-center {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        100% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
}
      


/* ----------------------------------------------
 * Generated by Animista on 2024-11-16 18:3:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes scale-up-center {
        0% {
          -webkit-transform: scale(0.5);
                  transform: scale(0.5);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      

/* ----------------------------------------------
 * Generated by Animista on 2024-11-16 18:9:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-bck-center
 * ----------------------------------------
 */
 @-webkit-keyframes slide-bck-center {
        0% {
          -webkit-transform: translateZ(0);
                  transform: translateZ(0);
        }
        100% {
          -webkit-transform: translateZ(-400px);
                  transform: translateZ(-400px);
        }
      }
      @keyframes slide-bck-center {
        0% {
          -webkit-transform: translateZ(0);
                  transform: translateZ(0);
        }
        100% {
          -webkit-transform: translateZ(-400px);
                  transform: translateZ(-400px);
        }
      }
      

      /* ----------------------------------------------
 * Generated by Animista on 2024-11-16 18:11:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-flicker-in-glow
 * ----------------------------------------
 */
@-webkit-keyframes text-flicker-in-glow {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 0;
          text-shadow: none;
        }
        10.1% {
          opacity: 1;
          text-shadow: none;
        }
        10.2% {
          opacity: 0;
          text-shadow: none;
        }
        20% {
          opacity: 0;
          text-shadow: none;
        }
        20.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
        }
        20.6% {
          opacity: 0;
          text-shadow: none;
        }
        30% {
          opacity: 0;
          text-shadow: none;
        }
        30.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.5% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.6% {
          opacity: 0;
          text-shadow: none;
        }
        45% {
          opacity: 0;
          text-shadow: none;
        }
        45.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        50% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55.1% {
          opacity: 0;
          text-shadow: none;
        }
        57% {
          opacity: 0;
          text-shadow: none;
        }
        57.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60.1% {
          opacity: 0;
          text-shadow: none;
        }
        65% {
          opacity: 0;
          text-shadow: none;
        }
        65.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75.1% {
          opacity: 0;
          text-shadow: none;
        }
        77% {
          opacity: 0;
          text-shadow: none;
        }
        77.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85.1% {
          opacity: 0;
          text-shadow: none;
        }
        86% {
          opacity: 0;
          text-shadow: none;
        }
        86.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        100% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
      }
      @keyframes text-flicker-in-glow {
        0% {
          opacity: 0;
        }
        10% {
          opacity: 0;
          text-shadow: none;
        }
        10.1% {
          opacity: 1;
          text-shadow: none;
        }
        10.2% {
          opacity: 0;
          text-shadow: none;
        }
        20% {
          opacity: 0;
          text-shadow: none;
        }
        20.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.25);
        }
        20.6% {
          opacity: 0;
          text-shadow: none;
        }
        30% {
          opacity: 0;
          text-shadow: none;
        }
        30.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.5% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        30.6% {
          opacity: 0;
          text-shadow: none;
        }
        45% {
          opacity: 0;
          text-shadow: none;
        }
        45.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        50% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.45), 0 0 60px rgba(255, 255, 255, 0.25);
        }
        55.1% {
          opacity: 0;
          text-shadow: none;
        }
        57% {
          opacity: 0;
          text-shadow: none;
        }
        57.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35);
        }
        60.1% {
          opacity: 0;
          text-shadow: none;
        }
        65% {
          opacity: 0;
          text-shadow: none;
        }
        65.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.35), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        75.1% {
          opacity: 0;
          text-shadow: none;
        }
        77% {
          opacity: 0;
          text-shadow: none;
        }
        77.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.55), 0 0 60px rgba(255, 255, 255, 0.4), 0 0 110px rgba(255, 255, 255, 0.2), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        85.1% {
          opacity: 0;
          text-shadow: none;
        }
        86% {
          opacity: 0;
          text-shadow: none;
        }
        86.1% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
        100% {
          opacity: 1;
          text-shadow: 0 0 30px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.45), 0 0 110px rgba(255, 255, 255, 0.25), 0 0 100px rgba(255, 255, 255, 0.1);
        }
      }
      

/* ----------------------------------------------
 * Generated by Animista on 2024-11-16 18:13:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-left {
        0% {
          -webkit-transform: translateX(-1000px);
                  transform: translateX(-1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-left {
        0% {
          -webkit-transform: translateX(-1000px);
                  transform: translateX(-1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateX(0);
                  transform: translateX(0);
          opacity: 1;
        }
      }
      

      /* ----------------------------------------------
 * Generated by Animista on 2024-11-17 2:35:55
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-focus-in
 * ----------------------------------------
 */
@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2024-11-22 15:52:50
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.bounce-out-top {
	-webkit-animation: bounce-out-top 3s both;
	        animation: bounce-out-top 3s both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-12-16 15:21:30
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-out-top
 * ----------------------------------------
 */
 @-webkit-keyframes bounce-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(-38px);
            transform: translateY(-38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-800px);
            transform: translateY(-800px);
    opacity: 0;
  }
}
@keyframes bounce-out-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  15% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  25% {
    -webkit-transform: translateY(-38px);
            transform: translateY(-38px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  52% {
    -webkit-transform: translateY(-75px);
            transform: translateY(-75px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  70% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  85% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-800px);
            transform: translateY(-800px);
    opacity: 0;
  }
}
