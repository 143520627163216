@import '../scss/colors.scss';
@import '../scss/font.scss';
@import '../scss/animations.scss';


.footer {
    margin-top: 4vw;

    .footer__text {
        font-size: 1vw;
        color: $azul-medio-2;
        font-weight: 600;
        font-family: "europa", sans-serif;
        text-align: center;
        margin-bottom: 0;
        padding-bottom: 10vw;
    }
}

#whatsapp {
    position: fixed;
    bottom: 5%;
    right: 5%;
    width: 5%;
    z-index: 9999;
}

@media only screen and (max-width: 767px) {


    .footer {
        margin-top: 20vw;
    
        .footer__text {
            font-size: 4vw;
            margin-bottom: 0;
            margin-top: 40vw;
            padding-bottom: 10vw;
        }
    }

    #whatsapp {
        bottom: 5%;
        right: 2%;
        width: 15%;
    }

}